import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets =  ["fields"]

  connect() {
    // console.log(this.fieldsTarget)
  }

  submitForm(event) {
    let isValid = this.validateFields(this.fieldsTarget);

    if (!isValid) {
      console.log("invalid")
      event.preventDefault();
    }
  }

  validateFields() {
    let isValid = true;

    // Tell the browser to find any required fields
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = this.fieldsTarget.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      // For each required field, check to see if the value is empty
      // if so, we focus the field and set our value to false
      field.classList.remove("invalid-field")
      console.log(field.value.trim())
      if (!field.disabled && !field.value.trim()) {
        field.classList.add("invalid-field");

        isValid = false;
      }
    });

    return isValid;
  }


}
