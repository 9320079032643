import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets =  ["invoice", "fees", "turnover", "activityAccount", "rFees", "baseSalary", "socialContributions", "netSalary", "form", "result"]


  calculate() {
    const rate = [0.94, 0.445, 0.555]
    const fees = Number(this.feesTarget.value)
    const feesT = new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR"}).format(fees)
    event.preventDefault()
    this.turnoverTarget.innerHTML =""
    this.activityAccountTarget.innerHTML = ""
    this.rFeesTarget.innerHTML = ""
    this.baseSalaryTarget.innerHTML = ""
    this.socialContributionsTarget.innerHTML = ""
    this.netSalaryTarget.innerHTML = ""

    const invoice = new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR"}).format(this.invoiceTarget.value)
    this.turnoverTarget.insertAdjacentHTML("beforeend", invoice)

    const activityAccountt = Number(`${this.invoiceTarget.value * rate[0]}`)
    const activityAccount= new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR"}).format(activityAccountt)
    this.activityAccountTarget.insertAdjacentHTML("beforeend", activityAccount)

    this.rFeesTarget.insertAdjacentHTML("beforeend", feesT)

    const baseSalary = `${activityAccountt - fees}`
    const baseSalaryT = new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR"}).format(baseSalary)
    this.baseSalaryTarget.insertAdjacentHTML("beforeend", baseSalaryT)

    const socialContributionsRounded = Number.parseFloat(`${baseSalary * rate[1]}`, 10).toFixed(2)
    const socialContributions = new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR"}).format(socialContributionsRounded)
    this.socialContributionsTarget.insertAdjacentHTML("beforeend", socialContributions)

    const salaireNetPlusFees = Number(`${baseSalary * rate[2] + fees}`)
    const netSalaryRounded = Number.parseFloat(`${salaireNetPlusFees}`, 10).toFixed(2)
    const netSalary = new Intl.NumberFormat("fr-FR", {style: "currency", currency: "EUR"}).format(netSalaryRounded)
    this.netSalaryTarget.insertAdjacentHTML("beforeend", netSalary)

    this.formTarget.classList.add("d-none")
    this.resultTarget.classList.remove("d-none")

  }

  recalculate() {
    this.formTarget.classList.remove("d-none")
    this.resultTarget.classList.add("d-none")
  }
}
