import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets =  ["password", "eye"]

  passwordToggle() {
    this.eyeTarget.classList.toggle("fa-eye-slash");
    this.eyeTarget.classList.toggle("fa-eye");
    var input = this.passwordTarget;
    if (input.getAttribute("type") == "password") {
      input.setAttribute("type", "text");
    } else {
      input.setAttribute("type", "password");
    }
  }
}
