// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

// you can also import a translation file
import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/plugins/monthSelect/style.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: French,
      altInput: true,

      plugins: [
        new monthSelectPlugin({
          shorthand: false, //defaults to false
          dateFormat: "Y-m-d", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
        })
      ]
    }
  }

}
