import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets =  ["navbar"]

  openburger() {
    this.navbarTarget.classList.toggle("show-nav")
  }

}
