import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets =  ["form", "mission", "field"]

  connect() {

  this.missionTarget.classList.remove("is-valid")
  }

  handleChange() {
    this.fieldTarget.value = this.missionTarget.value
    this.formTarget.submit()
  }
}
