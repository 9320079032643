import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "fields" ]

  connect() {
    this.stepIndex = 1;
    this.stepUpIncrements = [];
    this.steps = document.getElementsByClassName("steps");
    this.stepsLength = this.steps.length;
    this.buttonQuestionnaireInitialization();
    this.showSteps(this.stepIndex);
  }

  showSteps(n) {
    let i;
    this.initialization();
    this.stepIndex = this.borders(n)
    this.steps[this.stepIndex - 1].classList.remove("hidden");
    this.steps[this.stepIndex - 1].dataset.questionnaireTarget = "fields"
  }

  initialization() {
    let i;
    for (i = 0; i < this.stepsLength; i++) {
      this.steps[i].classList.add("hidden");
      this.steps[i].removeAttribute("data-questionnaire-target");
    }
  }

  borders(n) {
    if (n > this.stepsLength) {
      return this.stepsLength
    } else if (n < 1) {
      return 1
    } else {
      return n
    }
  }

  incrementSteps(n) {
    this.showSteps(this.stepIndex += n);
  }

  upStep(e) {
    e.preventDefault();
    let isValid = this.validateFields(this.fieldsTarget);
    console.log(isValid)

    if (isValid) {
      var step = parseInt(e.target.getAttribute("data-step-up"))
      this.incrementSteps(step);
     }
  }

  downStep(e) {
    e.preventDefault();
    var step = parseInt(e.target.getAttribute("data-step-down"))
    this.incrementSteps(-step);
  }

  buttonQuestionnaireInitialization() {
    let i;
    let buttons = document.getElementsByClassName("step-up");
    for (i = 0; i < buttons.length; i++) {
      buttons[i].dataset.action = "click->questionnaire#upStep"
    }
  }

  submitForm(e) {
    let isValid = this.validateFields(this.fieldsTarget);

    if (isValid) {
      this.formTarget.submit();
     } else {
      e.preventDefault();
    }
  }

  validateFields() {
    let isValid = true;
    // Tell the browser to find any required fields
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = this.fieldsTarget.querySelectorAll(requiredFieldSelectors);
    requiredFields.forEach((field) => {
      // For each required field, check to see if the value is empty
      // if so, we focus the field and set our value to false
      field.classList.remove("invalid-field")
      console.log(field.value.trim())
      if (!field.disabled && !field.value.trim()) {
        field.classList.add("invalid-field");
        isValid = false;
      }
    });
    return isValid;
  }
}
